import { ApprovalStatus } from '../enums/approvalStatus';
import { UserInfoApiModel, UserInfo } from '../userInfo';
import { ChangeRequest, ChangeRequestModel } from '../changeRequest';
import { PlannedOutageModel } from './plannedOutage';
import { OutageEventType } from './outageEventType';

export class ApprovalDetails {
  processedBy: UserInfo;
  processedDate: string;
  rejectReason: string;

  constructor(processedBy: UserInfoApiModel, processedDate: string, rejectReason: string) {
    this.processedBy = UserInfo.fromApiModel(processedBy);
    this.processedDate = processedDate;
    this.rejectReason = rejectReason;
  }
}

export class OutageRequest {
  // Specific to a revision
  uuid: string;
  originalOutageUUID: string;
  approvalStatus: ApprovalStatus;
  requestDate: Date;
  processedDate: Date;
  rejectReason?: string;

  // Request/approval workflow details
  approvalDetails: ApprovalDetails;
  changeRequest: ChangeRequest;

  // Fields specific to an outage
  uniqueId: number;
  outageEventType: OutageEventType;
  installedCapacity: number;
  expectedCapacity: number;
  additionalComments: string;
  derateAmount: number;
  jurisdiction: string;
  eventStart: Date;
  eventEnd: Date;

  // Old values of fields
  outageEventTypeOld?: string;
  additionalCommentsOld?: string;
  derateAmountOld?: number;
  expectedCapacityOld?: number;
  eventStartOld?: Date;
  eventEndOld?: Date;

  // Generated for table view
  siteNameDisplay: string;
  action: string;

  constructor(model: OutageRequestModel) {
    this.uuid = model.uuid;
    this.originalOutageUUID = model.original_outage_uuid;
    this.approvalStatus = model.approval_status;
    this.requestDate = model.request_date;
    this.processedDate = new Date(model.processed_date);
    this.approvalDetails = model.processed_by
      ? new ApprovalDetails(model.processed_by, model.processed_date, model.reason)
      : null;
    this.rejectReason =
      this.approvalStatus !== ApprovalStatus.Approved ? this.approvalDetails?.rejectReason : '';
    this.changeRequest = new ChangeRequest(model.change_request);

    this.uniqueId = model.unique_id;
    this.siteNameDisplay = model.site_name;
    this.outageEventType =
      (model.event_type as OutageEventType) ?? ((model.outage_type + ' Outage') as OutageEventType);
    this.installedCapacity = model.installed_capacity;
    this.expectedCapacity = model.expected_capacity;
    this.additionalComments = model.additional_comments;
    this.derateAmount = model.derate_amount ?? this.installedCapacity - this.expectedCapacity;
    this.jurisdiction = model.jurisdiction;
    this.eventStart = model.event_start;
    this.eventEnd = model.event_end;
    this.action = ``;
  }

  setCurrentOutageValues(outage: PlannedOutageModel) {
    this.outageEventTypeOld = outage.event_type !== this.outageEventType ? outage.event_type : null;

    this.additionalCommentsOld =
      outage.additional_comments !== this.additionalComments ? outage.additional_comments : null;

    this.derateAmountOld = outage.derate_amount !== this.derateAmount ? outage.derate_amount : null;

    this.expectedCapacityOld =
      outage.expected_capacity !== this.expectedCapacity ? outage.expected_capacity : null;

    this.eventStartOld =
      outage.event_start !== this.eventStart.toString() ? new Date(outage.event_start) : null;

    this.eventEndOld =
      outage?.event_end !== this.eventEnd.toString() ? new Date(outage?.event_end) : null;
  }
}

export class OutageRequestModel {
  // Specific to a revision
  uuid: string;
  original_outage_uuid: string;
  approval_status: ApprovalStatus;
  request_date: Date;
  processed_date: string;
  processed_by: UserInfoApiModel;
  reason: string;

  // Request/approval workflow details
  change_request: ChangeRequestModel;

  // Fields specific to an outage
  unique_id: number;
  site_name: string;
  event_type: string;
  outage_type: string;
  installed_capacity: number;
  expected_capacity: number;
  additional_comments: string;
  derate_amount: number;
  jurisdiction: string;
  event_start: Date;
  event_end: Date;
}

export class ApproveRejectOutageRequestModel {
  uuid: string;
  approval_status: ApprovalStatus;
  reason?: string;

  constructor(uuid: string, approvalStatus: ApprovalStatus, rejectReason?: string) {
    this.uuid = uuid;
    this.approval_status = approvalStatus;
    if (rejectReason) {
      this.reason = rejectReason;
    }
  }
}
