import { JurisdictionShortCode } from 'src/app/shared/models/jurisdiction';
import { GenerationViolation } from './generationViolation';
import {
  InterconnectionNotification,
  NoGenerationViolation,
  DisconnectNotice,
} from 'src/app/shared/models/interconnection';
import { AlertStatusChange } from './alertStatusChange';
import { LoggingService } from 'src/app/shared/services/logging.service';
import {
  NotificationDetailsEnum,
  NotificationDisplaySpec,
  NotificationDisplaySpecs,
} from '../siteFlagDetails';

export class SiteNotification {
  uuid: string;
  uniqueId: number;
  jurisdiction: JurisdictionShortCode;
  date: Date;
  notificationType: NotificationDetailsEnum;
  notificationDisplaySpec: NotificationDisplaySpec;
  breakerStatusChange?: AlertStatusChange;
  disconnectNotice?: DisconnectNotice;
  noGeneration?: NoGenerationViolation;
  overGeneration?: GenerationViolation;
  recloserStatusChange?: AlertStatusChange;
  siteName: string;

  private _logger = new LoggingService('Class_SiteNotification', 'ProgramManager');

  constructor(data: InterconnectionNotification) {
    this.date = new Date(data.dt_utc);

    this.uuid = data.uuid;
    this.uniqueId = data.unique_id;
    this.siteName = data.customer_legal_name;
    this.jurisdiction = data.jurisdiction as JurisdictionShortCode;
    this.notificationType = data.notification_type as NotificationDetailsEnum;
    this.notificationDisplaySpec =
      NotificationDisplaySpecs.getNotificationDisplaySpecsFromNotificationTypes(
        data.notification_type as NotificationDetailsEnum
      ) as NotificationDisplaySpec;

    switch (this.notificationType) {
      case NotificationDetailsEnum.BREAKER_STATUS_CHANGE:
        this.breakerStatusChange = {
          date: this.date,
          previousStatus: data.breaker_status_change?.previous_status,
          currentStatus: data.breaker_status_change?.current_status,
        };
        break;
      case NotificationDetailsEnum.DISCONNECT_NOTICE:
        this.disconnectNotice = {
          contract_account_number: data.disconnect_notice?.contract_account_number,
          delinquency_action_description: data.disconnect_notice?.delinquency_action_description,
          number_of_days: data.disconnect_notice?.number_of_days,
          updated_date: data.disconnect_notice?.updated_date,
          is_paid: data.disconnect_notice?.is_paid,
        };
        break;
      case NotificationDetailsEnum.NO_GENERATION:
        this.noGeneration = {
          start: data.no_generation?.start,
          end: data.no_generation?.end,
          last_generation_dt_utc: data.no_generation?.last_generation_dt_utc,
          number_of_days: data.no_generation?.number_of_days,
        };
        break;
      case NotificationDetailsEnum.OVER_GENERATION:
        this.overGeneration = {
          // Use UTC date as this notification is generated for the full day.
          date: this.date,
          excessMW: data.over_generation?.excess_mw,
          averageMW: data.over_generation?.average_mw,
          capacity: data.over_generation?.average_mw - data.over_generation?.excess_mw,
        };
        break;
      case NotificationDetailsEnum.RECLOSER_STATUS_CHANGE:
        this.recloserStatusChange = {
          date: this.date,
          previousStatus: data.recloser_status_change?.previous_status,
          currentStatus: data.recloser_status_change?.current_status,
        };
        break;
      default:
        this._logger.debug('Unhandled notification type in response', data.notification_type);
        break;
    }
  }
}
