import {
  Component,
  ViewEncapsulation,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Input,
  Output,
  ViewChild,
  ContentChildren,
  QueryList,
  EventEmitter,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoggingService } from '../../services/logging.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { FileService } from '../../services/utils/file.service';
import { GtmService } from '../../services/utils/gtm.service';
import { getNestedObjectProperty } from '../../utils/table-functions';
import { BaseComponent } from '../base.component';
import { DataCellComponent } from '../display-table/display-table.component';
import { TableCellDef, CellDataAlignment } from '../display-table/tableCellDef';
import { InputColumn, UserTableView, BOOL_OPTIONS } from './models';
import { TableType, TableWithFiltersService } from './table-with-filters.service';

/**
 * The main component for our smart table, table-with-filters. The template
 * consists of a combination of other components plus the actual mat-table.
 */
@Component({
  selector: 'app-table-with-filters',
  templateUrl: './table-with-filters.component.html',
  styleUrls: ['./table-with-filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableWithFiltersComponent<T>
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() tableType: TableType;
  @Input() tableExportTitle: string;
  @Input() showExportTable = true;
  @Input() tableCaption = 'Data Table';
  selectYear: string | number = new Date().getFullYear();
  /*
   default = addends summated for the totals row<br>
   custom = we are expecting other input {@link calcCustomTotals} to tell us how to display totals.
   Does not need to be set if passing in {@link customTotalsData}<br>
   none = totals row not used / hidden
   */
  @Input() totalsRowType: 'default' | 'custom' | 'none' = 'default';
  @Input() isSavingFiltersEnabled = true;
  @Input() set columns(columns: InputColumn[]) {
    this.allColumns = columns;
    this.setDisplayColumns();
    this.totalColumns = this.allColumns.filter((c) => c.display).map((c) => `${c.key}Total`);
    this.setTableDataColumns();
  }
  @Input() set tableData(data: T[]) {
    setTimeout(() => {
      this.buildDynamicMultiOptions(data);
      // The magic sauce for loading the table quickly with its number of ngIfs. Just
      //    wait until after the view inits on the first frame to populate the data.
      this.dataSource.data = data;
    });
  }

  /*
  When you need a totals row with custom keys/values.
  Properties of the passed-in object should match
  keys of the data being passed in where you want the value to appear.
  @see calcCustomTotals
 */
  @Input() set customTotalsData(totals: Record<string, string | number>) {
    this.customTotals = totals;
    this.totalsRowType = 'custom';
  }
  /**
   * Function to calculate values for a custom totals row. This function
   * exists as a callback whenever filters are applied, and only if
   * the input {@link customTotalsData} is used.
   */
  @Input() calcCustomTotals: (args: T[]) => void;

  @Input() paginatorSizeOptions: number[] = [10, 25, 50, 100];
  @Input() displayYearlySelector = false;
  @Input() displayTableColumnsOptions = true;
  @Input() displayTableColumnFilterOptions = true;

  @Output() columnsChanged: EventEmitter<InputColumn[]>;
  @Output() yearSelectionChanged = new EventEmitter<string>();

  @ViewChild('sortingTable') matSort: MatSort = new MatSort();

  @ContentChildren(DataCellComponent)
  set _columnComponents(columns: QueryList<DataCellComponent>) {
    this.additionalColumns = columns.map(
      (x) =>
        new TableCellDef(
          x.name,
          x.label,
          x.dataAccessor,
          x.type,
          x.alignment,
          x.dateFormat,
          x.cellTemplate
        )
    );
    const additionalTotalsDisplayColumns = this.additionalColumns
      ? this.additionalColumns.map((x) => `${x.name}Total`)
      : [];
    this.totalColumns?.push(...additionalTotalsDisplayColumns);
    this.setDisplayColumns();
  }

  public allColumns: InputColumn[] = [];
  public additionalColumns: TableCellDef[] = [];
  public dataSource = new MatTableDataSource<T>();
  public gridColumnAlignment = CellDataAlignment;
  protected paginator: MatPaginator;
  protected customTotals: Record<string, string | number>;
  protected filteredColumns: InputColumn[] = [];
  protected displayedColumns: string[];
  protected tableDataColumns: InputColumn[] = [];
  protected totalColumns: string[];
  protected showDecimal = false;

  getNestedObjectProperty = getNestedObjectProperty;

  private readonly _logger = new LoggingService('TableWithFiltersComponent');
  constructor(
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private fileService: FileService,
    private filtersService: TableWithFiltersService
  ) {
    super();
    this.columnsChanged = new EventEmitter<InputColumn[]>();
    this.dataSource.filterPredicate = this.getFilterPredicate();
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.dataSource.connect().subscribe(() => {
        if (this.totalsRowType === 'default') {
          this.calculateTotals(this.dataSource.filteredData);
        } else if (this.totalsRowType === 'custom' && this.dataSource.filteredData?.length > 0) {
          this.calcCustomTotals(this.dataSource.filteredData);
        }
      })
    );
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator?.page.subscribe((pageEvent) => {
      GtmService.clickEvent({
        category: 'dashboard',
        label: this.tableExportTitle,
        action: `pagination-size-${pageEvent.pageSize}-page-${pageEvent.pageIndex + 1}`,
      });
    });
    this.dataSource.sort = this.matSort;
    if (this.filtersService.hasFiltersForNamespace(this.tableExportTitle)) {
      const tmpFilters = this.filtersService.getFiltersForNamespace(this.tableExportTitle);

      for (const f of tmpFilters) {
        this.allColumns.forEach((c) => {
          if (f.key === c.key) {
            c.filter = f.filter;
          }
        });
      }

      this.updateMatTableFilters();
    }
  }

  /**
   * Translates filtered data into how it is displayed in the table (without pipes), and
   *  set the column headers as element keys (for header row). Finally, exports as .csv.
   */
  public exportTable() {
    const exportArray: Record<string, string | number>[] = [];

    this.buildExportTotalsRow(exportArray);
    this.buildExportDataRows(exportArray);

    this.fileService.downloadData(exportArray, `${this.tableExportTitle}.csv`);
    this.snackBar.success('Successfully exported table!');
  }

  /**
   * Sets filtration on the datasource and resets the paginator to the first page.
   */
  public refreshTableData(): void {
    this.filtersService.setFiltersForNamespace(this.tableExportTitle, this.filteredColumns);

    if (this.filteredColumns?.length < 1) {
      this.dataSource.filter = ''; // as per https://stackoverflow.com/questions/55541095/reset-angulars-mat-data-table-search-filter
    } else {
      this.dataSource.filter = JSON.stringify(this.filteredColumns ? this.filteredColumns : '');
    }
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  protected updateMatTableFilters() {
    this.setFilteredColumns();
    this.refreshTableData();
  }

  protected removeChip(column: InputColumn): void {
    delete column.filter;
    this.updateMatTableFilters();
  }

  protected pushSiteSelectionGaEvent(linkRoute: string) {
    let action = '';
    let shouldPushClickEvent = false;
    if (linkRoute.includes('site')) {
      action = `site-|-${linkRoute.match(/[0-9]+/)[0]}`;
      shouldPushClickEvent = true;
    } else if (linkRoute.includes('curtailment-logs')) {
      action = `event-date-clickthrough`;
      shouldPushClickEvent = true;
    }
    if (shouldPushClickEvent) {
      // GTM Doc
      // event-date-clickthrough - 5.5.4.4
      // site - 5.5.2.3, 5.5.3.3
      GtmService.clickEvent({
        category: 'reporting',
        label: this.tableExportTitle,
        // e.g. site-|-1001 or event-date-clickthrough
        action: action,
      });
    }
  }

  protected onTableDataApply(columns: InputColumn[]) {
    this.allColumns = [this.allColumns[0]].concat(columns);
    this.totalColumns = this.allColumns.filter((c) => c.display).map((c) => `${c.key}Total`);
    const additionalTotalsDisplayColumns = this.additionalColumns
      ? this.additionalColumns.map((x) => `${x.name}Total`)
      : [];
    if (additionalTotalsDisplayColumns.length > 0) {
      this.totalColumns?.push(...additionalTotalsDisplayColumns);
    }
    this.calculateTotals(this.dataSource.filteredData);
    this.setDisplayColumns();
    this.columnsChanged.emit(this.allColumns);
    this.snackBar.success('Successfully applied new table columns!');
  }

  protected yearSelected(year: string) {
    // GTM DOC - 5.6.2.2
    this.selectYear = year;
    GtmService.clickEvent({
      category: 'reporting',
      label: this.tableExportTitle,
      // e.g. yearly-summary-|-2024
      action: `yearly-summary-|-${GtmService.format(year)}`,
    });
    this.yearSelectionChanged.emit(year);
  }

  protected loadTableView(table: UserTableView) {
    // Keeping table.filters usage for backward compatibility
    const filteredColumns = table.filters ?? table.columns.filter((c) => c.filter);
    filteredColumns.forEach((c: InputColumn) => {
      this.validateQueryFilter(c);
    });
    // TODO: HOWEVER, if the columns are different since last saved, may not make sense.
    //  we would want the order to be similar, and adding filters, but still include updated column
    //  names/keys or additional columns
    this.allColumns = table.columns;
    this.buildDynamicMultiOptions(this.dataSource.data);
    this.totalColumns = this.allColumns.filter((c) => c.display).map((c) => `${c.key}Total`);
    const additionalTotalsDisplayColumns = this.additionalColumns
      ? this.additionalColumns.map((x) => `${x.name}Total`)
      : [];
    if (additionalTotalsDisplayColumns.length > 0) {
      this.totalColumns?.push(...additionalTotalsDisplayColumns);
    }
    this.setDisplayColumns();
    this.setTableDataColumns();
    this.updateMatTableFilters();
    this.snackBar.success(`Successfully loaded '${table.name}' table view!`);
  }

  protected getRowValue(element: object, key: string) {
    try {
      return key.split('.').reduce((o, i) => o[i], element);
    } catch (error) {
      return null;
    }
  }

  private setFilteredColumns(): void {
    this.filteredColumns = this.allColumns.filter((c) => c.filter);
  }

  /**
   * Used on dataSource.filterPredicate - implicitly used whenever filter is applied to dataSource.
   * filteredColumns is set to this.filteredColumns in the {@link refreshTableData} method.
   */
  private getFilterPredicate() {
    return (row: T, filteredColumns: string) => {
      let isMatch: boolean;
      const inputColumns: InputColumn[] = JSON.parse(filteredColumns) as InputColumn[];
      for (const c of inputColumns) {
        switch (c.type) {
          case 'bool':
            isMatch = this.handleBoolMatching(row[c.key] as boolean, c.filter.multiSearchValue);
            break;
          case 'mailtoEmail':
          case 'string':
          case 'html':
          case 'multi':
            isMatch = this.handleStringOrMultiMatching(row[c.key] as string | string[], c);
            break;
          case 'number':
          case 'addend':
            isMatch = this.handleNumberMatching(+row[c.key], c.filter.min, c.filter.max);
            break;
          case 'date':
            isMatch = this.handleDateMatching(
              new Date(row[c.key] as string | Date),
              // TODO: This should already be a date by now, but have to cast it
              new Date(c.filter.date)
            );
            break;
          default:
            isMatch = true;
            break;
        }

        // Doesn't match on at least one of our AND filters, so move on to next row
        if (!isMatch) {
          break;
        }
      }
      return isMatch;
    };
  }

  /**
   * Adds name and type to the filter based on column match
   * @param column
   */
  private validateQueryFilter(column: InputColumn): boolean {
    const filteredColumn = this.allColumns.find((c) => c.key === column.key);
    if (!filteredColumn) {
      this._logger.debug(`Filter with key ${column.key} doesn't apply to column set)`);
    }
    return !!filteredColumn;
  }

  /**
   * Returns the value of a key if the key is a nested lookup (ie he1.value)
   * @param data: dictionary of data looking for nested key in
   * @param key: key of nested lookup
   */
  private getValueOfNestedKey(data: unknown, key: string): string | number {
    const keySplit = key?.split('.');
    let value = data[keySplit[0]];
    for (let i = 1; i < keySplit.length; i++) {
      const curKey = keySplit[i];
      if (value !== undefined) {
        value = value[curKey];
      }
    }
    return value;
  }

  private calculateTotals(data: T[]): void {
    this.allColumns.forEach((c) => {
      if (c.type === 'addend') {
        const total = data.reduce((accum, curr) => {
          let valueToAdd = curr[c.key];

          // Check if key is referencing a nested lookup (ex: key is he1.value, need to look up curr['he1']['value'])
          if (c.key.indexOf('.') !== -1) {
            valueToAdd = this.getValueOfNestedKey(curr, c.key);
          }

          if (typeof valueToAdd !== 'number' && valueToAdd !== null) {
            return accum;
          }
          return +accum + +(valueToAdd ?? 0);
        }, 0);
        c.total = (+total).toFixed(2);
      }
    });
  }

  private setDisplayColumns(): void {
    this.displayedColumns = this.allColumns
      .filter((c) => c.display || c.name === 'Site Name')
      .map((c) => c.key);
    const additionalDisplayColumns = this.additionalColumns
      ? this.additionalColumns.map((x) => x.name)
      : [];
    this.displayedColumns.push(...additionalDisplayColumns);
  }

  private setTableDataColumns(): void {
    this.tableDataColumns = [...this.allColumns].splice(1);
  }

  private handleBoolMatching(datum: boolean, multiSearchValue: string[]): boolean {
    return (
      (multiSearchValue.includes('true') && datum === true) ||
      (multiSearchValue.includes('false') && datum === false) ||
      (multiSearchValue.includes('none') && datum === null)
    );
  }

  private handleStringOrMultiMatching(datum: string | string[], inputColumn: InputColumn): boolean {
    let filterValue: string;
    let isMatch = false;

    if (inputColumn.type === 'multi') {
      isMatch = inputColumn.filter.multiSearchValue.some((query) => query == datum);
      if (isMatch) {
        // matches on this one, make sure matches on others
        return isMatch;
      }

      if (inputColumn.filter.multiSearchValue?.length > 0) {
        // searchValue[0] here being, for example, 'Other', 'Blank', 'Empty', 'Null'
        filterValue = inputColumn.filter.multiSearchValue[0].trim().toLowerCase();
      } else {
        // Just to be safe
        filterValue = null;
      }
    }

    if (
      inputColumn.type === 'string' ||
      inputColumn.type === 'html' ||
      inputColumn.type === 'mailtoEmail'
    ) {
      filterValue = inputColumn.filter.searchValue.trim().toLowerCase();
    }

    // If no filter, returns everything with a value
    if (datum) {
      const dataStr = `${datum as string}`.trim().toLowerCase();
      isMatch = dataStr.indexOf(filterValue) != -1;
      // If the filter is other, include empty strings
    } else if (!isMatch && filterValue === 'none') {
      inputColumn.filter.searchValue = '';
      isMatch = true;
    } else {
      // Provide an option to search on fields with no values
      isMatch =
        filterValue === null ||
        filterValue === 'blank' ||
        filterValue === 'empty' ||
        filterValue === 'null' ||
        filterValue === 'none';
    }
    return isMatch;
  }

  private handleNumberMatching(datum: number, min: number, max: number): boolean {
    return datum >= min && datum <= max;
  }

  private handleDateMatching(datum: Date, filterDate: Date): boolean {
    return (
      datum?.getMonth() === filterDate.getMonth() &&
      datum?.getFullYear() === filterDate.getFullYear()
    );
  }

  /**
   * If no totals row, return. Else for each column of our filtered columns,
   * add value for the column onto our export array's totals row element.
   * @param exportArray is updated in the process
   * @private
   */
  private buildExportTotalsRow(exportArray: Record<string, string | number>[]): void {
    let index: number;
    if (this.totalsRowType === 'none') {
      return;
    } else {
      index = exportArray.push({}) - 1;
    }

    if (this.totalsRowType === 'custom') {
      for (const column of this.allColumns) {
        exportArray[index][column.name] = this.customTotals[column.key] || '';
      }
    } else if (this.totalsRowType === 'default') {
      for (const column of this.allColumns) {
        exportArray[index][column.name] = column.total;
      }
    }
  }

  /**
   * For every row of our filtered data, add a row to our exportArray and fill
   * in the values for each column of our filtered columns.
   * @param exportArray - is updated in the process
   * @private
   */
  private buildExportDataRows(exportArray: Record<string, string | number>[]): void {
    for (const datum of this.dataSource.filteredData) {
      const index = exportArray.push({}) - 1;
      for (const column of this.allColumns) {
        let columnValue = datum[column?.key] as string | number;
        // If column key is a nested lookup, get the inner value of the key
        if (column?.key.indexOf('.') !== -1) {
          columnValue = this.getValueOfNestedKey(datum, column?.key);
        }
        // Remove html tags used in data display when exporting data to file
        if (typeof columnValue === 'string') {
          columnValue = columnValue
            .replace('<span>', '')
            .replace('</span>', '')
            .replace('<div>', '')
            .replace('</div>', '');
        }
        exportArray[index][column?.name] = columnValue;
      }
    }
  }

  /**
   * For any column with the 'multi' type, we generate a sorted array of
   * distinct options based on the data's values.
   * @param data updated dataSource data coming in
   * @private
   */
  private buildDynamicMultiOptions(data: T[]): void {
    if (!data || data.length === 0) {
      return;
    }

    this.allColumns
      .filter((c) => c.type === 'multi')
      .forEach(
        (c) =>
          (c.filterOptions = [
            ...new Set<string>(data.map((d) => `${(d[c.key] as string) || 'none'}`)),
          ].sort())
      );

    // Technically non-dynamic, but makes sense to auto-assign filterOptions here
    this.allColumns
      .filter((c) => c.type === 'bool')
      .forEach((c) => (c.filterOptions = [...BOOL_OPTIONS]));
  }

  handleDecimalToggleChange() {
    this.showDecimal = !this.showDecimal;
  }
}
