import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GtmService } from 'src/app/shared/services/utils/gtm.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { AccountManagementService } from 'src/app/modules/account-management/services/account-management.service';
import { ToggleFilterSelection } from '../../models/toggleFilterSelection';

@Component({
  selector: 'app-account-header',
  templateUrl: './account-header.component.html',
})
export class AccountHeaderComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() selectedFlags: string[];
  @Input() onlyShowSelectedFlags = false;
  @Input() title: string;
  @Output() searchChange = new EventEmitter<string>();
  @Output() flagsUpdated = new EventEmitter<ToggleFilterSelection>();

  public searchValue = '';
  public allFlags = this.accountsService.flagIconArray;
  public flagArray = [];
  protected readonly filterImgUrl = 'app/shared/assets/icons/filter-list.svg';
  private _searchTextChanged: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _gtmServiceEventSent = false;

  constructor(protected accountsService: AccountManagementService) {
    super();
  }

  ngOnInit(): void {
    if (this.onlyShowSelectedFlags) {
      this.flagArray = this.allFlags.filter((flag) => this.selectedFlags.includes(flag.key));
    }
    this.subscriptions.push(
      this._searchTextChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((text) => {
        // GTM DOC - 5.4.3.1
        this.searchValue = text;
        if (this.searchValue && !this._gtmServiceEventSent) {
          this._gtmServiceEventSent = true;
          GtmService.clickEvent({
            category: 'program-manager',
            action: 'search',
            label: 'search-site-name-address',
          });
        }
        this.searchChange.emit(text);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.selectedFlags) {
      this.flagArray = this.allFlags.filter((flag) => this.selectedFlags.includes(flag.key));
    }
  }

  onTextChanged(text: string) {
    this._searchTextChanged.next(text);
  }

  updateFlags(isSelected: boolean, flag: string) {
    const onOffText = isSelected ? '-on' : '-off';
    // GTM DOC - 5.5.5.1
    GtmService.clickEvent({
      category: 'program-manager',
      action: `${GtmService.format(flag)}-|${onOffText}`,
      label: 'filter-button',
    });
    this.flagsUpdated.emit(new ToggleFilterSelection(isSelected, flag));
  }
}
