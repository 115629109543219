import { Component, ViewEncapsulation, Input } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ApprovalQueueService } from 'src/app/shared/services/approval-queue.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { ThemeService } from 'src/app/shared/services/utils/theme.service';

@Component({
  selector: 'app-pending-outage-requests',
  templateUrl: './pending-outage-requests.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class PendingOutageRequestsComponent {
  @Input() uniqueId?: number;

  private _unsubscribe$ = new Subject<void>();

  constructor(
    public outageRequestService: ApprovalQueueService,
    public themeService: ThemeService,
    protected snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    setTimeout(() => this.getOutageRequests());
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getOutageRequests(): void {
    this.outageRequestService
      .getPendingOutageRequests(this.uniqueId)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe();
  }
}
