import { getDuration } from '../../utils/date-time.util';
import { UserInfo, UserInfoApiModel } from '../userInfo';
import { OutageBase, OutageBaseModel } from './outageBase';

export class PlannedOutage extends OutageBase {
  uuid?: string;
  siteName: string;

  lastEditedBy?: UserInfo;
  lastEditedDate?: Date;
  createdBy?: UserInfo;
  createdDate?: Date;

  // for reporting
  uniqueIdDisplay?: string;

  action?: string;

  startDateTimeStr?: string;
  endDateTimeStr?: string;

  createdByStr?: string;
  lastEditedByStr?: string;

  outageEditRoute?: string;
  outageRoute?: string;

  // from Site model
  siteType?: string;

  // from outage request
  approvalStatus?: string;

  /**
   * Return the duration in seconds of the outage.
   */
  get duration(): string {
    return getDuration(this.startDateTime, this.endDateTime);
  }

  constructor(model: PlannedOutageModel) {
    super(model);
    this.uuid = model.uuid;
    this.siteName = model.site_name;
    this.lastEditedBy = UserInfo.fromApiModel(model.last_edited_by);
    this.lastEditedDate = new Date(model.last_edited_date);
    this.createdBy = UserInfo.fromApiModel(model.created_by);
    this.createdDate = new Date(model.created_date);
    this.uniqueIdDisplay = `${this.uniqueId}`.padStart(6, '0');
  }
}

export class PlannedOutageModel extends OutageBaseModel {
  uuid?: string;
  site_name?: string;

  last_edited_by?: UserInfoApiModel;
  last_edited_date?: string;
  created_by?: UserInfoApiModel;
  created_date?: string;

  constructor(outage: PlannedOutage) {
    super(outage);
    this.uuid = outage.uuid;
  }
}
