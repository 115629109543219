<div *ngIf="(outageRequestService.isLoadingOutageRequests$ | async) === false" [ngClass]="themeService.themeBackgroundClass">
  <div class="mb-24">
    <span *ngIf="!uniqueId" class="text-title"> Pending Outage Requests ({{ (outageRequestService.pendingOutageRequests$ | async).length
      }}) </span>
      <span *ngIf="uniqueId" class="text-title"> Submitted Outages</span>
  </div>

  <ng-container *ngIf="(outageRequestService.pendingOutageRequests$ | async).length > 0; else noData">
    <app-table-with-filters tableExportTitle="pending-outage-requests" [columns]="[]"
      [tableData]="outageRequestService.pendingOutageRequests$ | async" tableType="pending-outage-requests"
      totalsRowType="none" [isSavingFiltersEnabled]="false" [displayTableColumnsOptions]="false"
      [displayTableColumnFilterOptions]="false">
      <ng-template #requestedBy let-element="element" let-column="column">
        <span>
          {{element.changeRequest.requestedBy.fullName}} - {{element.changeRequest.requestedBy.email}}
        </span>
      </ng-template>
      <ng-template #requestedDate let-element="element" let-column="column">
        <span>
          {{element.changeRequest.requestDate | date: 'short'}}
        </span>
      </ng-template>
      <ng-template #outageEventTypeTemplate let-element="element" let-column="column">
        <div class="flex flex-row justify-around">
          <div class="flex flex-col">
            <div *ngIf="element.outageEventTypeOld">
              <span class="line-through">{{ element.outageEventTypeOld}}</span>
            </div>
            <mat-icon *ngIf="element.outageEventTypeOld" class="leading-[.8] mr-2">arrow_drop_down</mat-icon>
            <div>{{ element.outageEventType}}</div>
          </div>
        </div>
      </ng-template>
      <ng-template #derateAmountTemplate let-element="element" let-column="column">
        <div class="flex flex-row justify-around">
          <div class="flex flex-row">
            <div *ngIf="element.derateAmountOld" class="flex">
              <span class="line-through">{{ element.derateAmountOld }}</span>
            </div>
            <mat-icon *ngIf="element.derateAmountOld" class="leading-[.8] mr-2">arrow_right</mat-icon>
            <div>{{ element.derateAmount }}</div>
          </div>
        </div>
      </ng-template>
      <ng-template #expectedCapacityTemplate let-element="element" let-column="column">
        <div class="flex flex-row justify-around">
          <div class="flex flex-row">
            <div *ngIf="element.expectedCapacityOld" class="flex">
              <span class="line-through">{{ element.expectedCapacityOld }}</span>
            </div>
            <mat-icon *ngIf="element.expectedCapacityOld" class="leading-[.8] mr-2">arrow_right</mat-icon>
            <div>{{ element.expectedCapacity }}</div>
          </div>
        </div>
      </ng-template>
      <ng-template #eventStartTemplate let-element="element" let-column="column">
        <div class="flex flex-row justify-around">
          <div class="flex flex-col">
            <div *ngIf="element.eventStartOld">
              <span class="line-through">{{ element.eventStartOld | date: 'short'}}</span>
            </div>
            <mat-icon *ngIf="element.eventStartOld" class="leading-[.8] mr-2">arrow_drop_down</mat-icon>
            <div>{{ element.eventStart | date: 'short'}}</div>
          </div>
        </div>
      </ng-template>
      <ng-template #eventEndTemplate let-element="element" let-column="column">
        <div class="flex flex-row justify-around">
          <div class="flex flex-col">
            <div *ngIf="element.eventEndOld">
              <span class="line-through">{{ element.eventEndOld | date: 'short'}}</span>
            </div>
            <mat-icon *ngIf="element.eventEndOld" class="leading-[.8] mr-2">arrow_drop_down</mat-icon>
            <div>{{ element.eventEnd | date: 'short'}}</div>
          </div>
        </div>
      </ng-template>
      <ng-template #additionalCommentsTemplate let-element="element" let-column="column">
        <div class="flex flex-row">
          <div class="flex flex-col">
            <div *ngIf="element.additionalCommentsOld">
              <span class="line-through">{{ element.additionalCommentsOld}}</span>
            </div>
            <mat-icon *ngIf="element.additionalCommentsOld" class="leading-[.8] mr-2">arrow_drop_down</mat-icon>
            <div>{{ element.additionalComments}}</div>
          </div>
        </div>
      </ng-template>
      <app-data-cell name="requestDate" label="Requested On" [cellTemplate]="requestedDate"> </app-data-cell>
      <app-data-cell name="outageEventType" label="Event Type" [cellTemplate]="outageEventTypeTemplate">
      </app-data-cell>
      <app-data-cell name="installedCapacity" label="Installed Capacity">
      </app-data-cell>
      <app-data-cell name="derateAmount" label="Derate Amt" [cellTemplate]="derateAmountTemplate">
      </app-data-cell>
      <app-data-cell name="expectedCapacity" label="Expected Capacity" [cellTemplate]="expectedCapacityTemplate">
      </app-data-cell>
      <app-data-cell name="eventStart" label="Event Start" [cellTemplate]="eventStartTemplate">
      </app-data-cell>
      <app-data-cell name="eventEnd" label="Event Event" [cellTemplate]="eventEndTemplate">
      </app-data-cell>
      <app-data-cell name="additionalComments" label="Comments" [cellTemplate]="additionalCommentsTemplate">
      </app-data-cell>
    </app-table-with-filters>
  </ng-container>
  <ng-template #noData>
    <app-no-data [title]="'No pending outage request request data'"
      [summary]="'Pending Outage Requests will appear here'">
    </app-no-data>
  </ng-template>
</div>
