import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { Site } from 'src/app/shared/models/site';
import {
  InterconnectionModel,
  InterconnectionNotification,
} from 'src/app/shared/models/interconnection';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { SiteNotification } from 'src/app/shared/models/sites/siteNotification';
import { addDays, startOfDay } from 'date-fns';
import { NotificationDisplaySpecs } from 'src/app/shared/models/siteFlagDetails';

@Injectable({
  providedIn: 'root',
})
export class AccountManagementService extends ApiService {
  public isSitesLoading$ = new BehaviorSubject(false);
  public isAllAccountNotificationsLoading$ = new BehaviorSubject<boolean>(false);
  public allNotificationsByDateRange$ = new BehaviorSubject<SiteNotification[]>([]);
  public defaultStartDate$ = new BehaviorSubject<Date>(addDays(startOfDay(new Date()), -1)); // 1 day ago
  public filterNotifications$ = new BehaviorSubject<SiteNotification[]>([]);
  public selectedNotificationFlags$ = new BehaviorSubject<string[]>([]);

  public flagIconArray = NotificationDisplaySpecs.getAllProgramManagerToggleOptions();

  constructor() {
    super();
  }

  getExternalSites(flagged = false): Observable<Site[]> {
    this.isSitesLoading$.next(true);

    let params = new HttpParams();
    if (flagged) {
      params = ApiService.setHttpParam('flagged', 'true', params);
    }

    return this.http.get<InterconnectionModel[]>(`${this.apiUrl}/external/sites/`, { params }).pipe(
      map((resp) => resp.map((x) => Site.fromInterconnection(x))),
      catchError((err) => this.handleError('Unable to load program manager sites', err)),
      finalize(() => this.isSitesLoading$.next(false))
    );
  }

  getNotifications(
    startDate: Date,
    endDate: Date
  ): Observable<SiteNotification[] | InterconnectionNotification[]> {
    this.isAllAccountNotificationsLoading$.next(true);
    let params = new HttpParams();
    params = ApiService.setHttpParam('startDate', startDate.toISOString(), params);
    params = ApiService.setHttpParam('endDate', endDate.toISOString(), params);
    return this.http
      .get<InterconnectionNotification[]>(`${this.apiUrl}/external/sites/notifications`, { params })
      .pipe(
        finalize(() => this.isAllAccountNotificationsLoading$.next(false)),
        tap((resp: InterconnectionNotification[]) => {
          const notifications = resp.map((n) => new SiteNotification(n));
          this.allNotificationsByDateRange$.next(notifications);
          const uniqueFlags = notifications
            .map((n) => n.notificationType)
            .filter((value, index, self) => self.indexOf(value) === index);
          this.selectedNotificationFlags$.next(uniqueFlags);
          this.filterNotifications$.next(notifications);
        })
      );
  }
}
