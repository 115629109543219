import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatTabsModule } from '@angular/material/tabs';
import { AmendmentsComponent } from './components/macro-components/amendments/amendments.component';
import { BatteryComponent } from './components/macro-components/battery/battery.component';
import { PaymentHistoryComponent } from './components/macro-components/payment-history/payment-history.component';
import { SiteGenerationChartComponent } from './components/macro-components/site-generation/site-generation-chart.component';
import { SiteHeaderComponent } from './components/macro-components/site-header/site-header.component';
import { SiteNotificationsComponent } from './components/macro-components/site-notifications/site-notifications.component';
import { GenerationViolationsComponent } from './components/macro-components/generation-violations/generation-violations.component';
import { SitePlannedOutagesComponent } from './components/macro-components/site-planned-outages/site-planned-outages.component';
import { LabelComponent } from './components/macro-components/label/label.component';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SearchPipe } from './pipes/search.pipe';
import { MinutesToHoursMinutesPipe } from './pipes/minutes-to-hours-minutes.pipe';
import { PrefixPipe } from './pipes/prefix.pipe';
import { SuffixPipe } from './pipes/suffix.pipe';
import {
  DataCellComponent,
  DisplayTableComponent,
} from './components/display-table/display-table.component';
import { LoadingComponent } from './components/loading/loading.component';
import { RouterModule } from '@angular/router';
import { ToggleComponent } from './components/toggle/toggle.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NoDataComponent } from './components/no-data/no-data.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { DeIconComponent } from './components/de-icon/de-icon.component';
import { MonthSelectorComponent } from './components/month-selector/month-selector.component';
import { YearlySummarySelectorComponent } from './components/yearly-summary-selector/yearly-summary-selector.component';
import { MatRadioModule } from '@angular/material/radio';
import { MonthRangeSelectorComponent } from './components/month-range-selector/month-range-selector.component';
import { TableWithFiltersComponent } from './components/table-with-filters/table-with-filters.component';
import { TableViewsModalComponent } from 'src/app/shared/components/table-with-filters/table-footer-and-modals/table-modals/table-views-modal/table-views-modal.component';
import { SaveTableViewModalComponent } from './components/table-with-filters/table-footer-and-modals/table-modals/save-table-view-modal/save-table-view-modal.component';
import { ConfirmationComponent } from './components/modals/confirmation/confirmation.component';
import { JurisdictionSelectorComponent } from './components/jurisdiction-selector/jurisdiction-selector.component';
import { DeChipComponent } from './components/de-chip/de-chip.component';
import { TableColumnOptionsComponent } from 'src/app/shared/components/table-with-filters/table-column-options/table-column-options.component';
import { DataRefreshComponent } from './components/data-refresh/data-refresh.component';
import { MultiSelectModalComponent } from './components/multi-select-modal/multi-select-modal.component';
import { DateSelectorComponent } from 'src/app/shared/components/date-selector/date-selector.component';
import { MessageComponent } from './components/message/message.component';
import { LatestUpdatesModalComponent } from './components/modals/latest-updates-modal/latest-updates-modal.component';
import { DeButtonComponent } from './components/de-button/de-button.component';
import { DeFooterComponent } from './components/de-footer/de-footer.component';
import { DeCheckboxComponent } from './components/de-checkbox/de-checkbox.component';
import { DeModalComponent } from './components/de-modal/de-modal.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { ChipComponent } from './components/chip/chip.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { TableFiltersComponent } from './components/table-with-filters/table-filters/table-filters.component';
import { TableFilterChipsComponent } from './components/table-with-filters/table-filter-chips/table-filter-chips.component';
import { ArrayJoinPipe } from './pipes/array-join.pipe';
import { TableFooterAndModalsComponent } from './components/table-with-filters/table-footer-and-modals/table-footer-and-modals.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import {
  BaseFixedDateRangeDatepickerComponent,
  TwoDayDatepickerComponent,
  SevenDayDatepickerComponent,
} from 'src/app/shared/components/fixed-date-range-datepicker/fixed-date-range-datepicker.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ZeroToTwentyFourHundredHoursPipe } from './utils/source-comparison.util';
import { CustomToolTipComponent } from './components/custom-tool-tip/custom-tool-tip.component';
import { CustomToolTipRendererDirective } from './components/custom-tool-tip/custom-tool-tip.directive';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';
import { DatetimeComponent } from './components/datetime/datetime.component';
import { PlannedOutageComponent } from './components/macro-components/planned-outage/planned-outage.component';
import { RequiredRolesDirective } from './directives/required-roles.directive';
import { MatExpansionModule } from '@angular/material/expansion';
import { DePersonalInfoComponent } from './components/de-personal-info/de-personal-info.component';
import { OutageRequestHistoryComponent } from './components/macro-components/outage-requests/outage-request-history/outage-request-history.component';
import { OutageRequestsComponent } from './components/macro-components/outage-requests/outage-requests.component';
import { PendingOutageRequestsComponent } from './components/macro-components/outage-requests/pending-outage-requests/pending-outage-requests.component';
import { AccountHeaderComponent } from './components/account-header/account-header.component';

@NgModule({
  declarations: [
    AmendmentsComponent,
    ArrayJoinPipe,
    BaseFixedDateRangeDatepickerComponent,
    BatteryComponent,
    ChipComponent,
    ConfirmationComponent,
    CustomToolTipComponent,
    CustomToolTipRendererDirective,
    DataCellComponent,
    DataRefreshComponent,
    DateSelectorComponent,
    DatepickerComponent,
    DatetimeComponent,
    DeButtonComponent,
    DeCheckboxComponent,
    DeChipComponent,
    DeFooterComponent,
    DeIconComponent,
    DeModalComponent,
    DisplayTableComponent,
    FeedbackComponent,
    GenerationViolationsComponent,
    IconButtonComponent,
    JurisdictionSelectorComponent,
    LabelComponent,
    LatestUpdatesModalComponent,
    LoadingComponent,
    MessageComponent,
    MinutesToHoursMinutesPipe,
    MonthRangeSelectorComponent,
    MonthSelectorComponent,
    MultiSelectModalComponent,
    NoDataComponent,
    PaymentHistoryComponent,
    PlannedOutageComponent,
    SitePlannedOutagesComponent,
    PrefixPipe,
    RequiredRolesDirective,
    SaveTableViewModalComponent,
    SearchPipe,
    SevenDayDatepickerComponent,
    SiteGenerationChartComponent,
    SiteHeaderComponent,
    SiteNotificationsComponent,
    SuffixPipe,
    TableColumnOptionsComponent,
    TableFilterChipsComponent,
    TableFiltersComponent,
    TableFooterAndModalsComponent,
    TableViewsModalComponent,
    TableWithFiltersComponent,
    ToggleButtonComponent,
    TwoDayDatepickerComponent,
    YearlySummarySelectorComponent,
    ZeroToTwentyFourHundredHoursPipe,
    DatetimeComponent,
    DePersonalInfoComponent,
    OutageRequestsComponent,
    PendingOutageRequestsComponent,
    OutageRequestHistoryComponent,
    AccountHeaderComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    RouterModule,
    ToggleComponent,
    MatDialogModule,
    MatProgressBarModule,
    MatMenuModule,
    MatExpansionModule,
  ],
  exports: [
    AmendmentsComponent,
    ArrayJoinPipe,
    BatteryComponent,
    ChipComponent,
    CommonModule,
    ConfirmationComponent,
    CustomToolTipRendererDirective,
    DataCellComponent,
    DataRefreshComponent,
    DateSelectorComponent,
    DatepickerComponent,
    DatetimeComponent,
    DeButtonComponent,
    DeCheckboxComponent,
    DeChipComponent,
    DeFooterComponent,
    DeIconComponent,
    DeModalComponent,
    DisplayTableComponent,
    DragDropModule,
    FeedbackComponent,
    FormsModule,
    GenerationViolationsComponent,
    IconButtonComponent,
    JurisdictionSelectorComponent,
    LabelComponent,
    LatestUpdatesModalComponent,
    LoadingComponent,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MessageComponent,
    MinutesToHoursMinutesPipe,
    MonthRangeSelectorComponent,
    MonthSelectorComponent,
    MultiSelectModalComponent,
    NoDataComponent,
    PaymentHistoryComponent,
    PlannedOutageComponent,
    PrefixPipe,
    ReactiveFormsModule,
    RequiredRolesDirective,
    RouterModule,
    SearchPipe,
    SevenDayDatepickerComponent,
    SiteGenerationChartComponent,
    SiteHeaderComponent,
    SiteNotificationsComponent,
    SuffixPipe,
    TableColumnOptionsComponent,
    TableFilterChipsComponent,
    TableFiltersComponent,
    TableFooterAndModalsComponent,
    TableWithFiltersComponent,
    ToggleButtonComponent,
    ToggleComponent,
    TwoDayDatepickerComponent,
    YearlySummarySelectorComponent,
    ZeroToTwentyFourHundredHoursPipe,
    SitePlannedOutagesComponent,
    MatProgressBarModule,
    MatMenuModule,
    DatetimeComponent,
    TableFilterChipsComponent,
    MatExpansionModule,
    OutageRequestsComponent,
    PendingOutageRequestsComponent,
    OutageRequestHistoryComponent,
    AccountHeaderComponent,
  ],
  providers: [
    MatSnackBarModule,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } },
  ],
})
export class SharedModule {}
