import { UserInfo, UserInfoApiModel } from './userInfo';

export class ChangeRequest {
  requestedBy: UserInfo;
  requestDate: string;
  requestReason: string;

  constructor(model: ChangeRequestModel) {
    this.requestedBy = UserInfo.fromApiModel(model.requested_by);
    this.requestDate = model.request_date;
    this.requestReason = model.request_reason;
  }
}

export class ChangeRequestModel {
  requested_by: UserInfoApiModel;
  request_date: string;
  request_reason: string;

  constructor(model: ChangeRequest) {
    this.requested_by = new UserInfoApiModel(model.requestedBy);
    this.request_date = model.requestDate;
    this.request_reason = model.requestReason;
  }
}
