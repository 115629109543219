import { OutageEventType } from './outageEventType';

export class OutageBase {
  comments?: string;
  derateAmount?: number;
  endDateTime: Date;
  jurisdiction?: string;
  outageEventType: OutageEventType;
  siteCapacity: number;
  startDateTime: Date;
  temporaryExpectedCapacity: number;
  uniqueId: number;

  constructor(model: OutageBaseModel) {
    this.jurisdiction = model.jurisdiction;
    this.uniqueId = model.unique_id;
    this.siteCapacity = model.installed_capacity;
    this.temporaryExpectedCapacity = model.expected_capacity;
    this.derateAmount = model.derate_amount ?? this.siteCapacity - this.temporaryExpectedCapacity;
    this.startDateTime = new Date(model.event_start);
    this.endDateTime = model.event_end ? new Date(model.event_end) : null;
    this.outageEventType = model.event_type ?? ((model.outage_type + ' Outage') as OutageEventType);
    this.comments = model.additional_comments;
  }
}

export class OutageBaseModel {
  jurisdiction?: string;
  unique_id: number;
  event_type: OutageEventType;
  outage_type: OutageEventType;
  installed_capacity: number;
  expected_capacity: number;
  event_start: string;
  event_end: string;
  additional_comments?: string;
  derate_amount: number;

  constructor(outage: OutageBase) {
    this.unique_id = outage.uniqueId;
    this.jurisdiction = outage.jurisdiction;
    this.event_start = new Date(outage.startDateTime).toISOString();
    this.event_end = outage.endDateTime ? new Date(outage.endDateTime).toISOString() : null;
    this.additional_comments = outage.comments ?? '';
    this.event_type = outage.outageEventType;
    this.installed_capacity = outage.siteCapacity;
    this.expected_capacity = outage.temporaryExpectedCapacity;
    this.derate_amount = outage.derateAmount;
  }
}
