<div [ngClass]="title === 'site' ? 'bg-white px-[34px] py-14' : ''">
  <div class="flex flex-row items-center mt-10">
    <div class="w-3/4" [ngClass]="title === 'site' ? 'mb-20' : ''">
      <span>Site Flags</span>
    </div>
    <div class="w-[325px]" [ngClass]="title === 'site' ? 'hidden' : 'visible'">
      <div class="flex flex-end">
        <mat-form-field class="w-full">
          <img matPrefix src="app/shared/assets/icons/search-base.svg"
               class="invert-[44%] sepia-[10%] saturate-[17%] hue-rotate-[52deg] brightness-[92%] contrast-[97%] w-20 h-20 mr-8 mb-[5px]"
               alt="search" />
          <mat-label>Search Site name, address</mat-label>
          <input class="inline-block max-w-prose" matInput [(ngModel)]="searchValue"
                 (ngModelChange)="onTextChanged($event)" autocomplete="off" type="search" />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="w-3/4 inline">
    <app-toggle-button
      *ngFor="let item of accountsService.flagIconArray"
      [label]="item.programManagerDisplayName"
      [imgClass]="selectedFlags?.includes(item.key) ? 'site-flag--toggled' : 'site-flag--untoggled'"
      [imgUrl]="item.iconUrl"
      [imgAlt]="item.programManagerDisplayName"
      [active]="selectedFlags?.includes(item.key)"
      [matTooltip]="item.description"
      [matTooltipShowDelay]="1000"
      (toggleChange)="updateFlags($event, item.key)"
    >
    </app-toggle-button>
  </div>
</div>
