import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Site } from 'src/app/shared/models/site';

@Component({
  selector: 'app-site-pending-outage-requests',
  templateUrl: './pending-outage-requests.component.html',
  styleUrls: ['./pending-outage-requests.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SitePendingOutagesComponent {
  public selectedSite: Site;

  @Input() set site(site: Site) {
    if (site) {
      this.selectedSite = site;
    }
  }
}
