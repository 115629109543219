<div class="filter-section">
  <!-- Table Data Button -->
  <div *ngIf="displayTableColumnsOptions">
    <app-table-column-options [columns]="tableDataColumns" [tableExportTitle]="tableExportTitle"
      (columnsChanged)="onTableDataApply($event)">
    </app-table-column-options>
  </div>

  <!-- Column Filter Dropdown -->
  <div *ngIf="displayTableColumnFilterOptions">
    <app-table-filters [allColumns]="allColumns" (columnFilterUpdate)="updateMatTableFilters()"
      [tableExportTitle]="tableExportTitle" [selectedYear]="selectYear">
    </app-table-filters>
  </div>

  <div *ngIf="displayYearlySelector">
    <app-yearly-summary-selector (yearSelectionChanged)="yearSelected($event)"> </app-yearly-summary-selector>
  </div>
</div>

<!-- Chips / Pills -->
<div *ngIf="filteredColumns?.length > 0" class="chips-section">
  <app-table-filter-chips [filteredColumns]="filteredColumns"
    (chipRemoved)="removeChip($event)"></app-table-filter-chips>
</div>

<!-- Table Data -->

  <table aria-label="Table Data" mat-table matSort [dataSource]="dataSource" #sortingTable="matSort">
    <!-- Main body -->
    <ng-container *ngFor="let c of allColumns; let i = index" [matColumnDef]="c.key" [sticky]="i === 0">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        {{ c.name && c.year ? (c.name + ' (' + selectYear + ')' ): c.name }}
      </th>
    <td mat-cell *matCellDef="let element" matTooltipPosition="after"
      [matTooltip]="c.type !== 'html' ? getRowValue(element, c.key): null" [matTooltipShowDelay]="300">
      <span>
          <a *ngIf="c.route && c.type === 'html'" routerLink="{{ element[c.route] }}"
            (click)="pushSiteSelectionGaEvent(getRowValue(element, c.key)); getRowValue(element, c.key)"
            [innerHtml]="getRowValue(element, c.key)">
          </a>
          <a *ngIf="c.route && c.type !== 'html'" routerLink="{{ element[c.route] }}"
            (click)="pushSiteSelectionGaEvent(element[c.route])">
            {{ getRowValue(element, c.key) }}
          </a>
                  <span *ngIf="c.type === 'addend'">{{
          getRowValue(element, c.key) | number: c.digitsInfo || "1.0-0" | prefix: c.prefix | suffix: c.suffix
            }}</span>
          <span *ngIf="getRowValue(element, c.key) && c.type === 'number'">{{
          getRowValue(element, c.key) | number: c.digitsInfo || "1.0-0" | prefix: c.prefix | suffix: c.suffix
          }}</span>
        <span *ngIf="c.type === 'date'">{{
          getRowValue(element, c.key) | date: "shortDate"
            }}</span>
          <span *ngIf="c.type === 'html' && !c.route" [innerHtml]="getRowValue(element, c.key)"
            (click)="pushSiteSelectionGaEvent(getRowValue(element, c.key))"></span>
        <span *ngIf="c.type === 'mailtoEmail'"><a href="mailto:{{getRowValue(element, c.key)}}">{{getRowValue(element,
            c.key)}}</a></span>

        <span *ngIf="
              !c.route &&
              !(
                c.type === 'number' ||
                c.type === 'addend' ||
                c.type === 'date' ||
                c.type === 'html' ||
                c.type === 'mailtoEmail'
              ) &&
            c.key !== 'most_recent_curtailment_amount'
            ">{{ getRowValue(element, c.key) | prefix : c.prefix | suffix : c.suffix }}</span>
        </span>
      </td>
    </ng-container>
    <ng-container *ngFor="let column of additionalColumns" [matColumnDef]="column.name">
      <ng-container>
        <ng-container *ngIf="column.name !== 'action'; else actionHeader">
          <th mat-header-cell mat-sort-header *matHeaderCellDef [class.text-center]="column.alignment === gridColumnAlignment.Center"
            [class.text-right]="column.alignment === gridColumnAlignment.Right">
            {{ column.label }}
          </th>
        </ng-container>
        <ng-template #actionHeader>
          <th mat-header-cell *matHeaderCellDef [class.text-center]="column.alignment === gridColumnAlignment.Center"
            [class.text-right]="column.alignment === gridColumnAlignment.Right">
            {{ column.label }}
          </th>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="column.cellTemplate">
        <td mat-cell *matCellDef="let element" [attr.data-label]="column.label || column.name"
          [class.text-center]="column.alignment === gridColumnAlignment.Center"
          [class.text-right]="column.alignment === gridColumnAlignment.Right">
          <ng-template [ngTemplateOutletContext]="{
              element: element,
              column: column
            }" [ngTemplateOutlet]="column.cellTemplate">
          </ng-template>
        </td>
      </ng-container>
      <ng-container *ngIf="!column.cellTemplate">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.data-label]="column.label || column.name"
          [class.text-center]="column.alignment === gridColumnAlignment.Center"
          [class.text-right]="column.alignment === gridColumnAlignment.Right">
          {{ getNestedObjectProperty(element, column.name) }}
        </td>
      </ng-container>
    </ng-container>

    <!-- Display Default Totals Row -->
    <ng-container *ngIf="totalsRowType === 'default'">
      <ng-container *ngFor="let c of allColumns; index as i" [matColumnDef]="c.key + 'Total'" [sticky]="c.sticky">
        <th mat-header-cell *matHeaderCellDef>
        <span *ngIf="c.type === 'addend'">{{ c.total | number: "1.0-0" | prefix: c.prefix | suffix: c.suffix }}</span>
          <span *ngIf="i === 0"><strong>Totals (MW)</strong></span>
        </th>
      </ng-container>
      <ng-container *ngFor="let column of additionalColumns" [matColumnDef]="column.name + 'Total'">
        <th mat-header-cell *matHeaderCellDef>
        </th>
      </ng-container>
    </ng-container>

    <!-- Display Custom Totals Row -->
    <ng-container *ngIf="totalsRowType === 'custom'">
      <ng-container *ngFor="let c of allColumns; index as i" [matColumnDef]="c.key + 'Total'" [sticky]="c.sticky">
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="customTotals">{{ customTotals[c.key] | prefix : c.prefix | suffix : c.suffix }}</span>
          <span *ngIf="i === 0"><strong>Totals</strong></span>
        </th>
      </ng-container>
      <ng-container *ngFor="let column of additionalColumns" [matColumnDef]="column.name + 'Total'">
        <th mat-header-cell *matHeaderCellDef>
        </th>
      </ng-container>
    </ng-container>

      <!-- Two header rows - one for the column names, and one for the totals. -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="column-headers"></tr>
      <ng-container *ngIf="totalsRowType !== 'none'">
        <tr mat-header-row *matHeaderRowDef="totalColumns" class="total-header-row"></tr>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

<app-table-footer-and-modals *ngIf="showExportTable" [tableType]="tableType" [tableExportTitle]="tableExportTitle" [allColumns]="allColumns"
  [filteredColumns]="filteredColumns" [isSavingFiltersEnabled]="isSavingFiltersEnabled"
  [paginatorSizeOptions]="paginatorSizeOptions" (exportTable)="exportTable()" (paginator)="paginator = $event"
  (loadTable)="loadTableView($event)">
</app-table-footer-and-modals>
