import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { LoggingService } from './logging.service';
import { PlannedOutage, PlannedOutageModel } from '../models/outages/plannedOutage';
import { OutageBase, OutageBaseModel } from 'src/app/shared/models/outages/outageBase';

@Injectable({
  providedIn: 'root',
})
export class PlannedOutageService extends ApiService {
  // Submitted Outages
  public outagesPendingLoading = new BehaviorSubject(false);
  public outagesPendingData$ = new BehaviorSubject<OutageBase[]>([]);
  // Processed Outages
  public plannedOutagesLoading$ = new BehaviorSubject(false);
  public siteOutagesLoading$ = new BehaviorSubject(false);
  public siteOutageRequestsLoading$ = new BehaviorSubject(false);
  public isPlannedOutageLoading$ = new BehaviorSubject(false);
  private readonly _logger = new LoggingService('Dashboard', 'PlannedOutageService');

  public getPlannedOutage(uuid: string): Observable<PlannedOutage> {
    this.isPlannedOutageLoading$.next(true);

    return this.http.get<PlannedOutageModel>(`${this.apiUrl}/outages/${uuid}`).pipe(
      map((resp: PlannedOutageModel) => new PlannedOutage(resp)),
      catchError((err) => this.handleError('Unable to load planned outage', err)),
      finalize(() => this.isPlannedOutageLoading$.next(false))
    );
  }

  public getPendingOutages(): Observable<OutageBase[] | OutageBaseModel[]> {
    if (this.outagesPendingLoading.getValue()) {
      return;
    }
    this.outagesPendingLoading.next(true);

    return this.http.get<OutageBaseModel[]>(`${this.apiUrl}/outage-requests/`, {}).pipe(
      finalize(() => this.outagesPendingLoading.next(false)),
      tap((resp: OutageBaseModel[]) => {
        const submittedOutagesList = resp.map(
          (outagesBaseModel) => new OutageBase(outagesBaseModel)
        );
        this.outagesPendingData$.next(submittedOutagesList);
      }),
      catchError((err) => {
        return this.handleError('Unable to fetch Submitted Outages data', err);
      })
    );
  }

  public getPlannedOutages(start: string, end: string): Observable<PlannedOutage[]> {
    this.plannedOutagesLoading$.next(true);

    let params = ApiService.setHttpParam('startDate', start);
    if (end) {
      params = ApiService.setHttpParam('endDate', end, params);
    }

    return this.http.get<PlannedOutageModel[]>(`${this.apiUrl}/outages/`, { params }).pipe(
      map((resp: PlannedOutageModel[]) =>
        resp.map((plannedOutageModel) => new PlannedOutage(plannedOutageModel))
      ),
      catchError((err) => this.handleError('Unable to fetch planned outages', err)),
      finalize(() => this.plannedOutagesLoading$.next(false))
    );
  }

  public getSitePlannedOutages(
    uniqueId: number | string,
    startDate: Date,
    endDate: Date
  ): Observable<PlannedOutageModel[]> {
    this.siteOutagesLoading$.next(true);
    let params = ApiService.setStartEndDateParams(startDate, endDate);
    params = ApiService.setHttpParam('unique_id', uniqueId, params);
    return this.http.get<PlannedOutageModel[]>(`${this.apiUrl}/outages/`, { params }).pipe(
      catchError((err) => {
        return this.handleError(`Unable to load site ${uniqueId} planned outages.`, err);
      }),
      finalize(() => this.siteOutagesLoading$.next(false))
    );
  }

  savePlannedOutage(plannedOutage: PlannedOutageModel): Observable<PlannedOutageModel> {
    this._logger.info(`savePlannedOutage() given ${JSON.stringify(plannedOutage, null, 2)}`);
    return plannedOutage.uuid
      ? this.http.put<PlannedOutageModel>(
          `${this.apiUrl}/outages/${plannedOutage.uuid}`,
          plannedOutage
        )
      : this.http.post<PlannedOutageModel>(`${this.apiUrl}/outages/`, plannedOutage);
  }
}
